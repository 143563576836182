import { useEffect } from "react";
import { useNavigate } from "@reach/router";

function Portfolio() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/#portfolio", { replace: false });
  }, [navigate]);

  return null;
}

export default Portfolio;
